@use './colors';

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Regular.ttf);
}

html{
    font-family: Roboto, sans-serif;
}

body{
    font-family: Roboto, sans-serif;
}

.heading {
    font-family: 'Roboto', sans-serif;
    font-size:large;
    color:$primary;
}

.sub-heading {
    font-size:2em;    
    font-weight: 400;
    //color:colors.$primary;
    margin-bottom: 3%;
}

.main-text {
    font-size:1.1em;
    color:colors.$dark;
}


